import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Apple, GooglePlay } from "react-bootstrap-icons";
import MetaHeaders from "../../components/MetaHeaders";

const Download = () => {
  return (
    <>
      <MetaHeaders
        title="Download App"
        url="https://cabbily.com/download-app"
        image="https://cabbily.com/images/home.png"
        imageAlt="App homescreen"
        description="Carpool & bus bookings at your fingertips. Download the app today."
      />
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Get the App</h1>
              <p className="content-main-text fs-3">
                Carpool & bus bookings at your fingertips. Download the app
                today.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col>
              <a
                href="https://play.google.com/store/apps/details?id=com.cabbily.cabbily"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Card className="bg-white shadow flex-fill cursor-pointer animate">
                  <Card.Body className="d-flex flex-row align-items-center">
                    <div className="px-4 py-4 circle-wrapper me-md-2">
                      <GooglePlay size={40} color="#6434d3" />
                    </div>
                    <div>
                      <p className="fs-3 fw-bold mb-0">For Android</p>
                      <Card.Text className="content-main-text">
                        Download from the Google Play Store.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </a>
            </Col>
            <Col>
              <a
                href="https://apps.apple.com/us/app/cabbily/id1624355642"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Card className="bg-white shadow flex-fill cursor-pointer animate">
                  <Card.Body className="d-flex flex-row align-items-center">
                    <div className="px-4 py-4 circle-wrapper me-md-2">
                      <Apple size={40} color="#6434d3" />
                    </div>
                    <div>
                      <p className="fs-3 fw-bold mb-0">For iOS (Apple)</p>
                      <Card.Text className="content-main-text">
                        Download from the App Store.
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Download;
