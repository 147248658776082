import React from "react";
import { Row, Col, Card, Container, Image } from "react-bootstrap";
//import { Linkedin, XSquare } from "react-bootstrap-icons";
import MetaHeaders from "../../components/MetaHeaders";

const About = () => {
  return (
    <>
      <MetaHeaders
        title="About Us"
        url="https://cabbily.com/about"
        image="https://cabbily.com/images/elephant.jpg"
        imageAlt="Elephant in the savannah at sunset - golden hour"
        description="Traveling intercity to different parts of Uganda is unnecessarily complicated, that’s where we step in to help get you where you need to be in a convenient, affordable and secure way."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">About Us</h1>
              <h2 className="title-main-text">
                If You Want to Travel Uganda, We Will Help You
              </h2>

              <p className="content-main-text fs-3">
                Traveling intercity to different parts of Uganda is
                unnecessarily complicated, that’s where we step in to help get
                you where you need to be in a convenient, affordable and secure
                way.
              </p>
            </Col>
          </Row>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "right",
              //backgroundRepeat: "no-repeat",
            }}
          >
            <Col>
              <Row xs={1} md={3} className="g-4">
                <Col className="d-flex">
                  <Card className="bg-white flex-fill">
                    <Card.Body className="d-flex flex-row align-items-center">
                      <div className="px-3 py-3 circle-wrapper me-md-2">
                        <img
                          src="/images/bus.png"
                          height="22px"
                          className="d-inline-block align-top"
                          alt="Cabbily App showcase"
                        />
                      </div>
                      <div>
                        <p className="fs-4 fw-bold mb-0">
                          24/7 Customer Service
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="d-flex">
                  <Card className="bg-white shadow flex-fill">
                    <Card.Body className="d-flex flex-row align-items-center">
                      <div className="px-3 py-3 circle-wrapper me-md-2">
                        <img
                          src="/images/bus.png"
                          height="22px"
                          className="d-inline-block align-top"
                          alt="Cabbily App showcase"
                        />
                      </div>
                      <div>
                        <p className="fs-4 fw-bold mb-0">Convenient Bookings</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="d-flex">
                  <Card className="bg-white shadow flex-fill">
                    <Card.Body className="d-flex flex-row align-items-center">
                      <div className="px-3 py-3 circle-wrapper me-md-2">
                        <img
                          src="/images/bus.png"
                          height="22px"
                          className="d-inline-block align-top"
                          alt="Cabbily App showcase"
                        />
                      </div>
                      <div>
                        <p className="fs-4 fw-bold mb-0">
                          Multiple Travel Options
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={4}>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Our Story</h2>
              <p className="fs-3 fw-bold mb-0">How it Started?</p>
              <p className="content-main-text">
                In 2022, Cabbily sprouted from a traffic jam brainstorm! Picture
                two founders stuck in a Kampala cab, surrounded by solo riders.
                Ding! The light bulb moment hit—what if we help folks share
                rides and costs? Fast forward, and voila! Our carpooling app for
                Android and iOS was born. Less cars, less traffic, more
                eco-friendly adventures! Join the growing community—download,
                ride, and share the joy (and expenses)!
              </p>
              <p className="fs-3 fw-bold mb-0">How its going?</p>

              <p className="content-main-text">
                We have since added bus bookings to our offerings. We think of
                it as a carpool with way more people. The affordability of buses
                makes it a great option for intercity travelers and our goal is
                to make booking and taking a bus a very pleasant experience.
              </p>
            </Col>
            <Col>
              <Row className="g-4 align-items-center">
                <Col md={6}>
                  <Image src="/images/nile-bridge-jinja.jpeg" rounded fluid />
                </Col>
                <Col md={6}>
                  <Row className="g-4">
                    <Col md={8}>
                      <Image src="/images/elephant.jpg" rounded fluid />
                    </Col>
                    <Col sm={12}>
                      <Image
                        src="/images/busowoko-falls-jinja.jpg"
                        rounded
                        fluid
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "left",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Our Team</h2>
              <p className="fs-3 fw-bold mb-0">Here to serve you</p>
              <p className="content-main-text">
                The individuals gettting things done at cabbily.
              </p>
            </Col>
          </Row>
          <Row xs={1} md={6} className="g-4">
            <Col className="d-flex">
              <Card className="bg-white flex-fill shadow border-0">
                <Card.Body>
                  <Image src="/images/donald_haguma.jpeg" rounded fluid />
                  <p className="fs-6 fw-bold mb-0">Donald Haguma</p>
                  <Badge bg="primary">Cofounder/CTO</Badge>
                  <p className="mt-2">
                    <a href="" className="text-muted" rel="nofollow">
                      <Linkedin size={30} className="me-1" />
                    </a>
                    <a href="" className="text-muted" rel="nofollow">
                      <XSquare size={30} className="me-1" />
                    </a>
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">Cedric Daniel Lutalo</p>
                  <Badge bg="primary">Operations</Badge>
                  <p className="mt-2">
                    <a href="" className="text-muted" rel="nofollow">
                      <Linkedin size={30} className="me-1" />
                    </a>
                    <a href="" className="text-muted" rel="nofollow">
                      <XSquare size={30} className="me-1" />
                    </a>
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">Andrew Mani</p>
                  <Badge bg="primary">Marketing & Sales</Badge>
                  <p className="mt-2">
                    <a href="" className="text-muted" rel="nofollow">
                      <Linkedin size={30} className="me-1" />
                    </a>
                    <a href="" className="text-muted" rel="nofollow">
                      <XSquare size={30} className="me-1" />
                    </a>
                  </p>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">Ashaba Derrick</p>
                  <Badge bg="primary">Fullstack Engineer</Badge>
                  <p className="mt-2">
                    <a href="" className="text-muted" rel="nofollow">
                      <Linkedin size={30} className="me-1" />
                    </a>
                    <a href="" className="text-muted" rel="nofollow">
                      <XSquare size={30} className="me-1" />
                    </a>
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div> */}
    </>
  );
};

export default About;
