import "./App.scss";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

//Components
import Header from "./components/Header";
import Footer from "./components/Footer";

//Pages
import Home from "./pages/home/Home";
import BusService from "./pages/services/BusService";
import CarpoolService from "./pages/services/CarpoolService";
import Support from "./pages/support/Support";
import About from "./pages/about/About";
import Blog from "./pages/blog/Blog";
import Contact from "./pages/contact/Contact";
import Privacy from "./pages/others/Privacy";
import Terms from "./pages/others/Terms";
import Refund from "./pages/others/Refund";
import DeletePolicy from "./pages/others/DeletePolicy";
import Download from "./pages/others/Download";
import ErrorPage from "./pages/ErrorPage";
import Blogs from "./pages/blog/Blogs";

//- add a catchall route for broken routes.
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function App() {
  return (
    <div>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Header />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/buses">
                <BusService />
              </Route>
              <Route path="/carpool">
                <CarpoolService />
              </Route>
              <Route path="/support">
                <Support />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/blog/:slug">
                <Blog />
              </Route>
              <Route path="/blog">
                <Blogs />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/refund-policy">
                <Refund />
              </Route>
              <Route path="/delete-policy">
                <DeletePolicy />
              </Route>
              <Route path="/download-app">
                <Download />
              </Route>
              <Route path="*">
                <ErrorPage />
              </Route>
            </Switch>
            <Footer />
          </Router>
        </QueryClientProvider>
      </AuthProvider>
      <TawkMessengerReact
        propertyId="62be30a1b0d10b6f3e7a39ed"
        widgetId="1g6reru8u"
      />
    </div>
  );
}

export default App;
