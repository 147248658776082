import { Nav, Navbar, Button, Container, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Header() {
  return (
    <Navbar bg="white" data-bs-theme="light" className="navbar-top" expand="lg">
      <Container>
        <LinkContainer to={`/`}>
          <Navbar.Brand>
            <img
              src="/images/cabbily-logo-full.png"
              height="35"
              className="d-inline-block align-top"
              alt="Cabbily Logo"
            />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="main-nav" />
        <Navbar.Collapse id="main-nav" className="justify-content-end">
          <Nav className="custom-nav">
            <LinkContainer to={`/buses`}>
              <Nav.Link>Buses</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/carpool`}>
              <Nav.Link>Carpool</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/support`}>
              <Nav.Link>Help</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/about`}>
              <Nav.Link>About Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/blog`}>
              <Nav.Link>Blog</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/contact`}>
              <Nav.Link>Contact Us</Nav.Link>
            </LinkContainer>
            <LinkContainer to={`/download-app`}>
              <Button variant="outline-secondary">Download App</Button>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
