import axios from "axios";
//const BASE_URL = "http://localhost:3004/v1/";
const BASE_URL = "https://api.cabbily.com/v1/";
//const BASE_URL = "https://api-staging.cabbily.com/v1/admin/";
const deviceType = "Desktop";
const os = "Windows";
const osVersion = "1.0";
const signature =
  "z2d5akpzmbn0n0vyjptzlcsi59ucoedy4qir800zpsz9mgx1jbqikfov4zdb";
const appSecret = "kjafhJQKQJNWJNjqnjjqjnnNNWJNJQNJWJNjnqjjNQJNWJNQj";

export const mainApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": true,
    Accept: "*/*",
    "Content-Type": "application/json",
    "device-type": deviceType,
    os: os,
    "os-version": osVersion,
    signature: signature,
    "app-secret": appSecret,
  },
});

mainApi.defaults.headers.common["Content-Type"] = "application/json";

mainApi.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//BLOGS
export const getBlogs = async (params: any) => {
  const response = await mainApi.get(`blogs`, { params });
  return response.data;
};

export const getBlog = async (params: any) => {
  const response = await mainApi.get(`blogs/${params.slug}`);
  return response.data;
};

export const getBlogCategories = async (params: any) => {
  const response = await mainApi.get(`blogs/categories`, { params });
  return response.data;
};
