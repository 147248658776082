import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { StarFill } from "react-bootstrap-icons";
import MetaHeaders from "../../components/MetaHeaders";

const Refund = () => {
  return (
    <>
      <MetaHeaders
        title="Refund Policy"
        url="https://cabbily.com/refund-policy"
        description="We offer 100% money back guarantee subject to the terms below."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Refund Policy</h1>
              <p className="content-main-text fs-3">
                We offer 100% money back guarantee subject to the terms below.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col>
              <div className="d-flex flex-row">
                <StarFill
                  size={18}
                  className="ms-2 text-primary"
                  color="#6434d3"
                />
                <div className="ms-3">
                  <h4 className="mb-0 fs-4">
                    100% Refund: Driver unable to travel
                  </h4>
                  <p className="mt-2 fs-5 fw-normal">
                    We provide a 100% refund if the bus or carpool you booked
                    failed to travel to the destination for any reason. Note
                    that we only do the refund after confirming with the bus or
                    carpool that they did not travel.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <StarFill
                  size={18}
                  className="ms-2 text-primary"
                  color="#6434d3"
                />
                <div className="ms-3">
                  <h4 className="mb-0 fs-4">
                    100% Refund: Passenger cancellation
                  </h4>
                  <p className="mt-2 fs-5 fw-normal">
                    We provide a 100% refund if the passenger cancels a bus or
                    carpool booking 2 days before the trip. If cancellation is
                    done 2 days or less to the trip, we handle it on a case by
                    case basis depending on the circumstances and refund
                    accordingly.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <StarFill
                  size={18}
                  className="ms-2 text-primary"
                  color="#6434d3"
                />
                <div className="ms-3">
                  <h4 className="mb-0 fs-4">No Refund: Cancellation on Trip</h4>
                  <p className="mt-2 fs-5 fw-normal">
                    We provide no refund if the a passenger makes a cancellation
                    during a trip. It is up to the passenger to demand the
                    driver for a reasonable refund on trip should they cancel
                    for any reason. e.g. car breakdown, emergencies etc.
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row">
                <StarFill
                  size={18}
                  className="ms-2 text-primary"
                  color="#6434d3"
                />
                <div className="ms-3">
                  <h4 className="mb-0 fs-4">Partial Refund: All Cover</h4>
                  <p className="mt-2 fs-5 fw-normal">
                    For any item not covered above, Cabbily Technologies LTD
                    reserves the right to evaluate refund requests on a
                    case-by-case basis and issue refunds accordingly based on
                    the circumstances of the trip. In most cases, we will issue
                    a partial refund.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Refund;
