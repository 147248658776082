import React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getBlog } from "../../api/mainApi";
import MetaHeaders from "../../components/MetaHeaders";

const Blog = () => {
  let { slug } = useParams();
  //state
  //Queries & Mutations
  //Get categories
  const { data } = useQuery({
    queryKey: ["blog", slug],
    queryFn: () => getBlog({ slug: slug }),
  });

  return (
    <>
      <MetaHeaders
        title={data?.data?.title}
        url={`https://cabbily.com/blog/${slug}`}
        image={process.env.REACT_APP_IMAGE_URL + data?.data?.featured_image}
        imageAlt={data?.data?.featured_image_alt}
        description={data?.data?.meta_description}
      />
      <div
        className="section-gray blog"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <div className="d-flex flex-row align-items-center">
                <LinkContainer to={"/blog"}>
                  <p className="fs-4 fw-bold text-primary">Blog</p>
                </LinkContainer>
                <p className="fs-4 fw-bold text-secondary mx-2"> {" > "} </p>
                <p className="fs-4 fw-bold text-secondary">
                  {data?.data?.categoryDetail?.title}
                </p>
              </div>
              <h1 className="fs-1 fw-normal">{data?.data?.title}</h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row>
            <Col>
              <Image
                src={
                  process.env.REACT_APP_IMAGE_URL + data?.data?.featured_image
                }
                rounded
                fluid
                className="mb-2"
              />
              <div
                className="blog"
                dangerouslySetInnerHTML={{ __html: data?.data?.content }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Blog;
