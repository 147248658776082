import { Helmet } from "react-helmet";

const MetaHeaders = ({ title, url, image, imageAlt, description }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta property="og:type" content="website" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:image:alt" content={imageAlt} />
      <meta property="twitter:site" content="@cabbily" />
    </Helmet>
  );
};

export default MetaHeaders;
