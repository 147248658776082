import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  Badge,
  Spinner,
  InputGroup,
  Form,
} from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getBlogs, getBlogCategories } from "../../api/mainApi";
import { Functions } from "../../utilities";
import MetaHeaders from "../../components/MetaHeaders";

const Blogs = () => {
  //state
  const [blogCategory, setBlogCategory] = useState(null);
  //Queries & Mutations
  //Get categories
  const { isLoading: isLoadingCategories, data: dataCategories } = useQuery({
    queryKey: ["categories"],
    queryFn: () => getBlogCategories(),
  });

  //Get Blogs
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["blogs", blogCategory],
    queryFn: ({ pageParam = 0 }) =>
      getBlogs({
        page: pageParam,
        category_id: blogCategory,
        limit: 20,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  return (
    <>
      <MetaHeaders
        title="Blog"
        url="https://cabbily.com/blog"
        image="https://cabbily.com/images/couple-in-car.jpeg"
        imageAlt="Happy couple in car"
        description="Keep in the know about us. Lots of detailed articles about our service and mentions of us in the press."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col md={9}>
              <h1 className="fs-4 fw-bold text-primary">Blog</h1>
              <h2 className="title-main-text">Latest articles</h2>

              <p className="content-main-text fs-3">
                Keep in the know about us. Lots of detailed articles about our
                service and mentions of us in the press.
              </p>
            </Col>
            <Col md={3}>
              {!isLoadingCategories && dataCategories && (
                <InputGroup className="mb-3">
                  <Form.Select
                    size="lg"
                    value={blogCategory}
                    onChange={(e) => setBlogCategory(e.target.value)}
                  >
                    <option value="">All Categories</option>
                    {dataCategories?.data?.map((item, index) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.title}
                        </option>
                      );
                    })}
                  </Form.Select>
                </InputGroup>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          {status === "error" ? (
            <p className="text-center"> {Functions.handleError(error)}</p>
          ) : status === "pending" ? null : (
            <Row xs={1} md={4} className="g-4">
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <Col className="d-flex" key={item?.id}>
                      <Card className="flex-fill">
                        <Card.Img
                          variant="top"
                          src={
                            process.env.REACT_APP_IMAGE_URL +
                            item?.featured_image
                          }
                          style={{ objectFit: "cover" }}
                        />
                        <Card.Body className="px-4">
                          <Badge bg="primary">
                            {item?.categoryDetail?.title}
                          </Badge>
                          <p className="fs-4 fw-bold">{item?.title}</p>
                          <LinkContainer to={`/blog/${item?.slug}`}>
                            <Button variant="outline-secondary" size="sm">
                              Read More{" "}
                              <ArrowRight size={22} className="me-2 ms-2" />
                            </Button>
                          </LinkContainer>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </React.Fragment>
              ))}
            </Row>
          )}
        </Container>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || status === "pending") && !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
    </>
  );
};

export default Blogs;
