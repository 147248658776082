import { Nav, Container, Col, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function Footer() {
  return (
    <footer className="py-5">
      <Container>
        <Row>
          <Col md={3} xs={6}>
            <img
              src="/images/cabbily-logo-full.png"
              height="35"
              className="d-inline-block align-top"
              alt="Cabbily Logo"
            />
            <p className="mt-2">
              Cabbily Technologies LTD,
              <br />
              Plot 8, Mutungo Zone 1, Nakawa
              <br />
              Kampala, Uganda
            </p>
          </Col>
          <Col md={2} xs={6}>
            <Nav className="flex-column custom-nav">
              <p className="menu-text mb-0 mt-1 ms-3 fw-bold">SUPPORT</p>
              <LinkContainer to={`/support`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Help & Support</p>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/blog`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Blog</p>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/contact`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Contact Us</p>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/about`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">About Us</p>
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>
          <Col md={2} xs={6}>
            <Nav className="flex-column custom-nav">
              <p className="menu-text mb-0 mt-1 ms-3 fw-bold">COMPANY</p>
              <LinkContainer to={`/privacy`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Privacy Policy</p>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/terms`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Terms & Conditions</p>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/refund-policy`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Refund Policy</p>
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={`/delete-policy`}>
                <Nav.Link className="text-secondary mb-0 pb-0">
                  <p className="menu-text mb-0 pb-0">Account Deletion Policy</p>
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Col>
          <Col md={2} xs={6}>
            <Nav className="flex-column custom-nav">
              <p className="menu-text mb-0 mt-1 ms-3 fw-bold">SOCIALS</p>
              <Nav.Link
                className="text-secondary mb-0 pb-0"
                href="https://x.com/cabbily"
                target="_blank"
                rel="nofollow"
              >
                <p className="menu-text mb-0 pb-0">Twitter</p>
              </Nav.Link>
              <Nav.Link
                className="text-secondary mb-0 pb-0"
                href="https://linkedin.com/company/cabbily"
                target="_blank"
                rel="nofollow"
              >
                <p className="menu-text mb-0 pb-0">LinkedIn</p>
              </Nav.Link>
            </Nav>
          </Col>
          <Col md={3}>
            <p className="menu-text mb-0 mt-1 fw-bold text-center">
              DOWNLOAD APP
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.cabbily.cabbily"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <div className="d-flex flex-row justify-content-center">
                <img
                  src="/images/android.png"
                  height="65"
                  alt="Download on Android"
                />
              </div>
            </a>
            <a
              href="https://apps.apple.com/us/app/cabbily/id1624355642"
              target="_blank"
              rel="nofollow noreferrer"
            >
              <div className="d-flex flex-row justify-content-center">
                <img
                  src="/images/apple.svg"
                  height="50"
                  alt="Download on Apple"
                />
              </div>
            </a>
          </Col>
        </Row>
        <div className="py-2 border-top mt-3">
          <p className="text-muted text-center">
            © 2022 - 2024 Cabbily Technologies LTD
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
