import React from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  Badge,
  Image,
} from "react-bootstrap";
import {
  Icon1Circle,
  Icon2Circle,
  Icon3Circle,
  ArrowRight,
} from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import MetaHeaders from "../../components/MetaHeaders";

const BusService = () => {
  return (
    <>
      <MetaHeaders
        title="Bus Booking Services"
        url="https://cabbily.com/buses"
        image="https://cabbily.com/images/bus.svg"
        imageAlt="Bus vector graphic."
        description="Book a seat from your phone. We provide bus bookings for your preferred bus companies in Uganda. Plan your travel with ease using our app. All booking, scheduling and payment is done in app so all you need to do is pack your bags and board the bus at the bus station."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Bus Bookings</h1>
              <h2 className="title-main-text">Book a seat from your phone</h2>

              <p className="content-main-text fs-3">
                We provide bus bookings for your preferred bus companies in
                Uganda. Plan your travel with ease using our app. All booking,
                scheduling and payment is done in app so all you need to do is
                pack your bags and board the bus at the bus station.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            className="align-items-center"
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={5}>
              <Image src="/images/bus.svg" fluid />
            </Col>
            <Col md={7}>
              <h2 className="fs-6 mb-0 text-primary fw-normal">THE HOW</h2>
              <p className="fs-3 fw-bold">How it works</p>

              <Row xs={1} md={3} className="g-4">
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon1Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Book a bus</p>
                    </div>
                    <p className="content-main-text">
                      Select a bus, choose the number of seats you want and book
                      from the available buses.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon2Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Pay for Trip</p>
                    </div>
                    <p className="content-main-text">
                      Use the available payment options to pay for the booking.
                      We have multiple online payment options available. Upon
                      payment, your bus ticket is processed.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon3Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Board Bus & Travel</p>
                    </div>
                    <p className="content-main-text">
                      Go to the bus park, show your ticket information and board
                      the bus to your destination. We provide all the
                      information required to board the bus in the app.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            className="pt-5 pb-5 mb-5 mt-5 align-items-center"
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={6}>
              <h2 className="fs-6 mb-0 text-primary fw-normal">THE WHY</h2>
              <p className="fs-3 fw-bold mb-0">Excellent Support</p>
              <p className="content-main-text">
                We provide 24/7 Customer Support to sort out any issues. Booking
                with us is convenient, it’s all done on your phone and we
                provide a 100% money back guarantee should you fail to travel.
                In summary, we care about your travels and will make sure you
                get to where you need to go.
              </p>
              <LinkContainer to={`/download-app`} className="animate">
                <Button variant="outline-primary" size="lg">
                  Book a Bus Seat Today{" "}
                  <ArrowRight size={22} className="me-2 ms-2" />
                </Button>
              </LinkContainer>
            </Col>
            <Col md={6}>
              <Image src="/images/support.svg" fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "left",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-4 fw-bold mb-0 text-primary"> Bus Partners</h2>
              <p className="fs-3 fw-bold mb-0">Who we work with.</p>
              <p className="content-main-text">
                Some of our highly esteemed partners
              </p>
            </Col>
          </Row>
          <Row xs={1} md={6} className="g-4">
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">BABY COACH</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">FRIEND BUS</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">HMK BUSES</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">MAKOME BUS SERVICES</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">MEGA BUS COMPANY</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">NILE STAR</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BusService;
