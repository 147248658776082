import React from "react";
import {
  Row,
  Col,
  Card,
  Accordion,
  Button,
  Container,
  Badge,
  Image,
} from "react-bootstrap";
import {
  ArrowRight,
  Wallet2,
  ShieldCheck,
  HandThumbsUp,
  Clock,
  CashCoin,
  CalendarHeart,
} from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getBlogs } from "../../api/mainApi";
import MetaHeaders from "../../components/MetaHeaders";

const Home = () => {
  //QUERIES
  //Get Home Blogs
  const { isLoading, data } = useQuery({
    queryKey: ["homeBlogs"],
    queryFn: () => getBlogs({ limit: 4 }),
  });

  return (
    <>
      <MetaHeaders
        title="Cabbily - carpool & bus"
        url="https://cabbily.com"
        image="https://cabbily.com/images/bg-main-web.png"
        imageAlt="3 cabbily mobile app screenshots showcase"
        description="We help users in Uganda book buses or share a ride with friends with no hassles and at affordable prices. Book or post a trip from the comfort of your smartphone and schedule the time you would like to travel."
      />
      <div
        className="section-default"
        style={{
          backgroundImage: 'url("/images/bg-main-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row className="d-flex flex-row align-items-center py-5">
            <Col md="6">
              <h1 className="title-main-text">
                <span className="text-primary">Intercity</span> trips simplified
              </h1>
              <h2 className="subtitle-main-text">
                Book buses and carpool trips
              </h2>
              <p className="content-main-text">
                We help users in Uganda book buses or share a ride with friends
                with no hassles and at affordable prices. Book or post a trip
                from the comfort of your smartphone and schedule the time you
                would like to travel.
              </p>
              <LinkContainer to={`/download-app`}>
                <Button variant="outline-primary" size="lg">
                  Download App <ArrowRight size={22} className="me-2 ms-2" />
                </Button>
              </LinkContainer>
            </Col>
            <Col className="d-flex align-items-center justify-content-center">
              <Image
                src="/images/app-image.png"
                rounded
                fluid
                alt="Cabbily App showcase"
              />
              {/* <img
                src="/images/app-image.png"
                height="400"
                className="d-inline-block align-top"
                alt="Cabbily App showcase"
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "right",
              //backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={4}>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Services</h2>
              <p className="fs-3 fw-bold mb-0">Lots of happy customers</p>
              <p className="content-main-text">
                Schedule and book your long distance trip hassle free. We help
                our customers focus more on what they'll do at their destination
                and we'll handle how you get there.
              </p>
            </Col>
            <Col>
              <Row xs={1} md={2} className="g-4">
                <Col className="d-flex">
                  <LinkContainer to={`/buses`} className="animate">
                    <Card className="bg-white shadow flex-fill cursor-pointer">
                      <Card.Body className="d-flex flex-row align-items-center">
                        <div className="px-4 py-4 circle-wrapper me-md-2">
                          <img
                            src="/images/bus.png"
                            height="70px"
                            className="d-inline-block align-top"
                            alt="Cabbily App showcase"
                          />
                        </div>
                        <div>
                          <p className="fs-3 fw-bold mb-0">Bus Booking</p>
                          <Card.Text className="content-main-text">
                            Book a seat on a bus of your choice and we take it
                            from there. All you have to do is board the bus and
                            be on your way
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>
                <Col className="d-flex">
                  <LinkContainer to={`/carpool`} className="animate">
                    <Card className="bg-white shadow flex-fill cursor-pointer">
                      <Card.Body className="d-flex flex-row align-items-center">
                        <div className="px-4 py-4 circle-wrapper me-md-2">
                          <img
                            src="/images/carpool.png"
                            height="70px"
                            className="d-inline-block align-top"
                            alt="Cabbily App showcase"
                          />
                        </div>
                        <div>
                          <p className="fs-3 fw-bold mb-0">Carpool Booking</p>
                          <Card.Text className="content-main-text">
                            We enable drivers and passengers going the same way
                            to linkup and travel together sharing travel costs,
                            traveling in comfort and making new friends along
                            the way.
                          </Card.Text>
                        </div>
                      </Card.Body>
                    </Card>
                  </LinkContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={4}>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Features</h2>
              <p className="fs-3 fw-bold mb-0">Why Choose Us?</p>
              <p className="content-main-text">
                Plan your travel from the comfort of your couch. Multiple bus
                trips and carpools available for you to book seamlessly online.
              </p>
            </Col>
            <Col>
              <Row xs={1} md={3} className="g-4">
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <CalendarHeart
                      size={45}
                      className="ms-2 mb-2 text-primary"
                      color="#6434d3"
                    />
                    <p className="fw-bold mb-0">Convenience</p>
                    <p className="content-main-text">
                      Plan your travel from the comfort of your couch. Multiple
                      bus trips and carpools available for you to book
                      seamlessly online.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <Wallet2
                      size={45}
                      className="ms-2 mb-2 text-primary"
                      color="#6434d3"
                    />
                    <p className="fw-bold mb-0">Economical Trip</p>
                    <p className="content-main-text">
                      Fair and clear pricing with different price ranges
                      depending on your choice of travel. No extra costs or
                      hidden fees.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <ShieldCheck
                      size={45}
                      className="ms-2 mb-2 text-primary"
                      color="#6434d3"
                    />
                    <p className="fw-bold mb-0">Secure & Safe</p>
                    <p className="content-main-text">
                      Detailed booking information provided with in-app chat to
                      enable drivers and passengers communicate before taking a
                      trip. Buses are made available with licensed bus
                      operators. View Partners
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <HandThumbsUp
                      size={45}
                      className="ms-2 mb-2 text-primary"
                      color="#6434d3"
                    />
                    <p className="fw-bold mb-0">Money Back Guarantee</p>
                    <p className="content-main-text">
                      100% refund available to clients in case a trip is not
                      taken. It is subject to our Refund Policy. View Refund
                      Policy.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <Clock
                      size={45}
                      className="ms-2 mb-2 text-primary"
                      color="#6434d3"
                    />
                    <p className="fw-bold mb-0">24/7 Customer Support</p>
                    <p className="content-main-text">
                      We are online at all times to assist you along your
                      journey. Get in touch with us for any reason, we will
                      respond.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <CashCoin
                      size={45}
                      className="ms-2 mb-2 text-primary"
                      color="#6434d3"
                    />
                    <p className="fw-bold mb-0">Seamless Payments</p>
                    <p className="content-main-text">
                      MTN Momo, Airtel Money and Cash Payment options available
                      in app. Book with your method of choice.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section-gray">
        <Container>
          <Row>
            <Col>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Blog</h2>
              <p className="fs-3 fw-bold mb-0">Latest articles</p>
              <p className="content-main-text">
                Keep in the know about us. Lots of detailed articles about our
                service and mentions of us in the press.
              </p>
            </Col>
          </Row>
          {!isLoading && data && (
            <Row xs={1} md={4} className="g-4">
              {data?.data?.map((item, index) => {
                return (
                  <Col className="d-flex" key={item?.id}>
                    <Card className="flex-fill">
                      <Card.Img
                        variant="top"
                        src={
                          process.env.REACT_APP_IMAGE_URL + item?.featured_image
                        }
                        style={{ objectFit: "cover" }}
                      />
                      <Card.Body className="px-4">
                        <Badge bg="primary">
                          {item?.categoryDetail?.title}
                        </Badge>
                        <p className="fs-4 fw-bold">{item?.title}</p>
                        <LinkContainer to={`/blog/${item?.slug}`}>
                          <Button variant="outline-secondary" size="sm">
                            Read More{" "}
                            <ArrowRight size={22} className="me-2 ms-2" />
                          </Button>
                        </LinkContainer>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}

          <Row>
            <Col className="mt-3 d-flex justify-content-center align-items-center">
              <LinkContainer to={`/blog`}>
                <Button variant="outline-primary" size="sm">
                  View All <ArrowRight size={22} className="me-2 ms-2" />
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-default"
        style={{
          backgroundImage: 'url("/images/bg-vertical-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Help & Support</h2>
              <p className="fs-3 fw-bold mb-0">Frequently Asked Questions</p>
              <p className="content-main-text">
                Get info on questions you might have.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    What services do you offer?
                  </Accordion.Header>
                  <Accordion.Body>
                    We offer bus booking services and carpool services.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>How can I book a carpool?</Accordion.Header>
                  <Accordion.Body>
                    You can book a carpool through our app by selecting the
                    carpool option and entering your trip details.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>How can I book a bus?</Accordion.Header>
                  <Accordion.Body>
                    You can book a bus through our app by selecting the bus
                    booking option and choosing your route and schedule.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    Are your services available 24/7?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, both our carpooling and bus booking services are
                    available 24/7.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    What payment methods do you accept?
                  </Accordion.Header>
                  <Accordion.Body>
                    We accept various payment methods including Airtel Money,
                    MTN Momo and cash payments.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Can I cancel my ride?</Accordion.Header>
                  <Accordion.Body>
                    Yes, you can cancel your ride through our app, but
                    cancellation fees may apply depending on the timing.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    How do I create an account?
                  </Accordion.Header>
                  <Accordion.Body>
                    You can create an account by downloading our app and
                    following the registration prompts.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    Is there a fee to sign up?
                  </Accordion.Header>
                  <Accordion.Body>
                    No, signing up for an account is free of charge.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    Do I need to verify my phone number?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, for security purposes, we require users to verify their
                    phone number during the registration process.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 d-flex justify-content-center align-items-center">
              <LinkContainer to={`/support`}>
                <Button variant="outline-primary" size="sm">
                  View All <ArrowRight size={22} className="me-2 ms-2" />
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "left",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-4 fw-bold mb-0 text-primary">Partners</h2>
              <p className="fs-3 fw-bold mb-0">Who we work with.</p>
              <p className="content-main-text">
                Some of our highly esteemed partners
              </p>
            </Col>
          </Row>
          <Row xs={1} md={6} className="g-4">
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">BABY COACH</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">FRIEND BUS</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">HMK BUSES</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">MAKOME BUS SERVICES</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">MEGA BUS COMPANY</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
            <Col className="d-flex">
              <Card className="bg-white flex-fill">
                <Card.Body>
                  <p className="fs-6 fw-bold mb-0">NILE STAR</p>
                  <Badge bg="secondary">Bus Company</Badge>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 d-flex justify-content-center align-items-center">
              <LinkContainer to={`/contact`}>
                <Button variant="outline-primary" size="sm">
                  Become a Partner{" "}
                  <ArrowRight size={22} className="me-2 ms-2" />
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Home;
