import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Container,
  Form,
  InputGroup,
  Alert,
} from "react-bootstrap";
import MetaHeaders from "../../components/MetaHeaders";

const Contact = () => {
  const [result, setResult] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    console.log(event.target);
    const formData = new FormData(event.target);

    formData.append("access_key", "d707fafc-04fe-4b86-9e43-ebf2cfb82e75");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <>
      <MetaHeaders
        title="Contact Us"
        url="https://cabbily.com/contact"
        image="https://cabbily.com/images/support.svg"
        imageAlt="Customer Support vector graphic."
        description="Get in Touch. We endeavor to answer all enquiries within 24 hours on business days. We will be happy to answer your questions."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Contact Us</h1>
              <h2 className="title-main-text">Get in Touch</h2>

              <p className="content-main-text fs-3">
                We endeavor to answer all enquiries within 24 hours on business
                days. We will be happy to answer your questions.
              </p>
            </Col>
          </Row>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "right",
              //backgroundRepeat: "no-repeat",
            }}
          >
            <Col>
              <Row xs={1} md={3} className="g-4">
                <Col className="d-flex">
                  <Card className="bg-white flex-fill">
                    <Card.Body className="d-flex flex-row align-items-center">
                      <div className="px-3 py-3 circle-wrapper me-md-2">
                        <img
                          src="/images/bus.png"
                          height="22px"
                          className="d-inline-block align-top"
                          alt="Cabbily App showcase"
                        />
                      </div>
                      <div>
                        <p className="fs-4 fw-bold mb-0">24/7 Live Chat</p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="d-flex">
                  <Card className="bg-white shadow flex-fill">
                    <Card.Body className="d-flex flex-row align-items-center">
                      <div className="px-3 py-3 circle-wrapper me-md-2">
                        <img
                          src="/images/bus.png"
                          height="22px"
                          className="d-inline-block align-top"
                          alt="Cabbily App showcase"
                        />
                      </div>
                      <div>
                        <p className="fs-4 fw-bold mb-0">
                          8AM to 6PM - Phone/WhatsApp
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="d-flex">
                  <Card className="bg-white shadow flex-fill">
                    <Card.Body className="d-flex flex-row align-items-center">
                      <div className="px-3 py-3 circle-wrapper me-md-2">
                        <img
                          src="/images/bus.png"
                          height="22px"
                          className="d-inline-block align-top"
                          alt="Cabbily App showcase"
                        />
                      </div>
                      <div>
                        <p className="fs-4 fw-bold mb-0">
                          Within 24Hrs - Email
                        </p>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={4}>
              <h2 className="fs-4 fw-bold mb-0 text-primary">
                Support Options
              </h2>
              <p className="fs-3 fw-bold mb-0">Phone</p>
              <p className="content-main-text">+256702590207</p>
              <p className="fs-3 fw-bold mb-0">WhatsApp</p>
              <p className="content-main-text">+256762020500</p>
              <p className="fs-3 fw-bold mb-0">Resources</p>
              <p className="content-main-text">Live Chat</p>
              <p className="content-main-text">Help & Support</p>
              <p className="content-main-text">Blog</p>
            </Col>
            <Col>
              <h2 className="fs-6 mb-0 text-primary fw-normal">EMAIL</h2>
              <p className="fs-3 fw-bold mb-0">Send us a message</p>
              <Alert variant="primary" className="mt-2">
                {result}
              </Alert>
              <div>
                <Form onSubmit={onSubmit}>
                  <Form.Group controlId="message" className="mt-2">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="name"
                      name="name"
                      required
                    />
                    <Form.Text className="text-muted">
                      Enter you full name.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="message" className="mt-2">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="email"
                      name="email"
                      required
                    />
                    <Form.Text className="text-muted">
                      Input correct email so we can respond to query.
                    </Form.Text>
                  </Form.Group>
                  <Form.Group controlId="ride-status" className="mt-2">
                    <Form.Label>Email Subject</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Select name="subject" required>
                        <option value="Customer Support">
                          Customer Support
                        </option>
                        <option value="Billing">Billing</option>
                        <option value="Partner">Partner</option>
                        <option value="General Inquiry">General Inquiry</option>
                        <option value="Other">
                          Other - Specify in Message
                        </option>
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="message" className="mt-2">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="message"
                      rows={3}
                      placeholder="Type your message here..."
                    />
                  </Form.Group>
                  <Form.Control
                    type="hidden"
                    name="from_name"
                    value="Cabbily Web"
                  />
                  <Form.Control
                    type="checkbox"
                    name="botcheck"
                    className="hidden"
                    style={{ display: "none" }}
                    value="Cabbily Web"
                  />
                  <Button variant="primary" type="submit" className="mt-3">
                    Send Message
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contact;
