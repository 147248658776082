import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import MetaHeaders from "../../components/MetaHeaders";

const DeletePolicy = () => {
  return (
    <>
      <MetaHeaders
        title="Account Deletion Policy"
        url="https://cabbily.com/delete-policy"
        image="https://cabbily.com/images/support.svg"
        imageAlt="Customer Support vector graphic."
        description="Cabbily provides resources to allow a user delete their account and associated data from cabbily in its entirety."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Delete Account</h1>

              <p className="content-main-text fs-3">
                Cabbily provides resources to allow a user delete their account
                and associated data from cabbily in its entirety.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col>
              <strong>Steps To Delete Account</strong>
              <p>
                1. Contact us on the email team@cabbily.com. The title of your
                email must include “Account Deletion Request”.
              </p>

              <p>
                2. In the body of the email, provide your phone number
                (international format), name and associated email used on the
                account.
              </p>

              <p>
                3. We will reach out to you via phone number or email to confirm
                your deletion request.
              </p>

              <p>
                4. Your data will be deleted within 90 days of the request being
                made.
              </p>

              <strong>Data deleted includes;</strong>

              <p>
                – All personally identifiable information that could be used to
                identify you as a user such as phone, name, email, location
                data, trips taken, rides taken and you will be removed from all
                groups you joined on the platform.
              </p>

              <p>
                – All financial transaction data will be deleted. This includes,
                all payment records and received payout information.
              </p>

              <strong>Data we retain includes;</strong>

              <p>
                – Non-identifiable information used for analytics purposes such
                as Google Analytics data. The information retained cannot be
                used to identify you as a user.
              </p>

              <p>– App usage statistics.</p>

              <p>
                For further inquiries, <a href="/contact">Contact Us</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DeletePolicy;
