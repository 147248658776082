import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Container,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  StarFill,
  Dot,
  Box2,
  List,
  CarFront,
  People,
  PersonGear,
  BookmarkCheck,
  PersonPlus,
} from "react-bootstrap-icons";
import MetaHeaders from "../../components/MetaHeaders";

const faq = [
  {
    category: "services",
    question: "What services do you offer?",
    answer: "We offer bus booking services and carpool services.",
    priority: "high",
  },
  {
    category: "services",
    question: "How can I book a carpool?",
    answer:
      "You can book a carpool through our app by selecting the carpool option and entering your trip details.",
    priority: "high",
  },
  {
    category: "services",
    question: "How can I book a bus?",
    answer:
      "You can book a bus through our app by selecting the bus booking option and choosing your route and schedule.",
    priority: "high",
  },
  {
    category: "services",
    question: "What areas do you serve?",
    answer:
      "We serve multiple cities and regions. You can check the available areas in our app.",
    priority: "high",
  },
  {
    category: "services",
    question: "Are your services available 24/7?",
    answer:
      "Yes, both our carpooling and bus booking services are available 24/7.",
    priority: "low",
  },
  {
    category: "services",
    question: "How do I cancel a booking?",
    answer:
      "You can cancel a booking through our app by going to your bookings and selecting the cancel option.",
    priority: "high",
  },
  {
    category: "services",
    question: "Is there a fee for canceling a booking?",
    answer:
      "Cancellation fees may apply depending on the timing of your cancellation. Please refer to our refund policy.",
    priority: "high",
  },
  {
    category: "services",
    question: "Do you offer group discounts?",
    answer:
      "Yes, we offer group discounts for bus bookings. Please contact our support team for more details.",
    priority: "low",
  },
  {
    category: "services",
    question: "Can I modify my booking?",
    answer:
      "Yes, you can modify your booking through our app, subject to availability and terms.",
    priority: "high",
  },
  {
    category: "services",
    question: "What payment methods do you accept?",
    answer:
      "We accept various payment methods including Airtel Money, MTN Momo and cash payments.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "How can I become a driver?",
    answer:
      "You can become a driver by signing up through our app and completing the driver registration process.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "What are the requirements to become a driver?",
    answer:
      "Drivers must have a valid driver's license and meet our vehicle requirements.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "How do I get paid?",
    answer:
      "Drivers are paid when they submit a funds withdraw request that is processed within 24 hours.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "Can I set my own schedule?",
    answer:
      "Yes, drivers can choose their own hours and work when it suits them.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "What support is available for drivers?",
    answer:
      "We offer 24/7 driver support through our app and dedicated helpline.",
    priority: "low",
  },
  {
    category: "drivers",
    question: "How are fares calculated?",
    answer:
      "Carpool drivers set their own fares. We provide a recommended price based on distance, fuel cost and time. Detailed fare information is available in our app.",
    priority: "low",
  },
  {
    category: "drivers",
    question: "Do I need special insurance?",
    answer:
      "No. Insurance is handled by the driver and you get to choose what insurance works for you.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "What if I need to cancel a ride?",
    answer:
      "Drivers can cancel a ride if necessary, but repeated cancellations may affect their rating and standing.",
    priority: "high",
  },
  {
    category: "drivers",
    question: "How do I maintain a high rating?",
    answer:
      "Providing excellent service, being punctual, and maintaining a clean vehicle help maintain a high rating.",
    priority: "low",
  },
  {
    category: "drivers",
    question: "What incentives are available for drivers?",
    answer:
      "We offer various incentives such as waiving platform fees for high performance drivers.",
    priority: "low",
  },
  {
    category: "passengers",
    question: "How do I book a ride?",
    answer:
      "You can book a ride through our app by entering your destination and selecting either carpool or bus booking.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "How do I pay for my ride?",
    answer:
      "You can pay for your ride using various payment methods including Airtel Money, MoMo Pay and cash.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "Can I cancel my ride?",
    answer:
      "Yes, you can cancel your ride through our app, but cancellation fees may apply depending on the timing.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "How do I know my driver’s details?",
    answer:
      "Once your ride is confirmed, you will receive the driver’s details including name, contact number, and vehicle information.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "Is there a way to rate my driver?",
    answer:
      "Yes, you can rate your driver and provide feedback after your ride through our app.",
    priority: "low",
  },
  {
    category: "passengers",
    question: "What should I do if I leave something in the vehicle?",
    answer:
      "If you leave something in the vehicle, you can contact the driver directly through the app or reach out to our support team.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "Are pets allowed on rides?",
    answer:
      "Pets are allowed on bus rides with appropriate carriers. For carpool rides, it depends on the driver’s preference.",
    priority: "low",
  },
  {
    category: "passengers",
    question: "Can I book a ride in advance?",
    answer:
      "Yes, you can schedule a ride in advance through our app by selecting the preferred date and time.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "What safety measures are in place?",
    answer:
      "Our drivers undergo background checks, and we have safety features like ride tracking and emergency contacts in the app.",
    priority: "high",
  },
  {
    category: "passengers",
    question: "Can I share my ride with others?",
    answer:
      "Yes, our carpool service allows you to share your ride with other passengers traveling in the same direction.",
    priority: "low",
  },
  {
    category: "partners",
    question: "How can I become a partner?",
    answer:
      "You can become a partner by filling out the partner application form on our website or through our app.",
    priority: "high",
  },
  {
    category: "partners",
    question: "What are the benefits of partnering with you?",
    answer:
      "As a partner, you will receive access to our large customer base, marketing support, and potential revenue growth.",
    priority: "high",
  },
  {
    category: "partners",
    question: "What types of partnerships do you offer?",
    answer:
      "We offer various partnerships including bus operators, car rental services, and corporate collaborations.",
    priority: "high",
  },
  {
    category: "partners",
    question: "How does revenue sharing work?",
    answer:
      "Revenue sharing is based on a commission model, where partners earn a percentage of the total booking amount.",
    priority: "high",
  },
  {
    category: "partners",
    question: "What support do you provide to partners?",
    answer:
      "We provide 24/7 partner support, training materials, and marketing assistance to help you succeed.",
    priority: "low",
  },
  {
    category: "partners",
    question: "Can I access real-time data on bookings?",
    answer:
      "Yes, partners have access to a dedicated dashboard where they can view real-time data on bookings and performance.",
    priority: "high",
  },
  {
    category: "partners",
    question: "How do I manage my fleet or services?",
    answer:
      "You can manage your fleet or services through our partner portal, which allows you to update availability, pricing, and more.",
    priority: "high",
  },
  {
    category: "partners",
    question: "What marketing opportunities are available?",
    answer:
      "We offer various marketing opportunities including featured listings, promotions, and co-branded campaigns.",
    priority: "low",
  },
  {
    category: "partners",
    question: "How often are payments processed?",
    answer:
      "Payments for bus companies are processed when we contact the company to issue tickets for a trip.",
    priority: "high",
  },
  {
    category: "partners",
    question: "What happens if there is a dispute?",
    answer:
      "In the event of a dispute, our support team will work with you to resolve the issue promptly and fairly.",
    priority: "low",
  },
  {
    category: "bookings",
    question: "How do I make a booking?",
    answer:
      "You can make a booking through our app by selecting either carpool or bus booking, entering your trip details, and confirming your reservation.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "Can I modify my booking?",
    answer:
      "Yes, you can modify your booking through our app by going to the 'My Bookings' section and selecting the option to modify your trip.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "What is the cancellation policy?",
    answer:
      "Our cancellation policy varies based on the type of booking. Detailed information is available in the app under the 'Cancellation Policy' section.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "Is there a fee for canceling a booking?",
    answer:
      "Cancellation fees may apply depending on the timing of your cancellation. Please refer to our cancellation policy in the app.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "How can I view my booking history?",
    answer:
      "You can view your booking history in the app by navigating to the 'My Bookings' section.",
    priority: "low",
  },
  {
    category: "bookings",
    question: "What payment methods are accepted?",
    answer:
      "We accept various payment methods including Airtel Money, MoMo Pay and cash.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "How do I know if my booking is confirmed?",
    answer:
      "You will receive a confirmation notification in the app and the status of the booking will show accepted in the app.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "Can I book for someone else?",
    answer:
      "Yes, you can book for someone else by entering their details during the booking process.",
    priority: "low",
  },
  {
    category: "bookings",
    question: "What should I do if I encounter an issue with my booking?",
    answer:
      "If you encounter any issues with your booking, please contact our support team through the app for assistance.",
    priority: "high",
  },
  {
    category: "bookings",
    question: "How far in advance can I make a booking?",
    answer:
      "You can make a booking as far in advance as the schedule allows, typically up to 1 month in advance.",
    priority: "low",
  },
  {
    category: "getting started",
    question: "How do I create an account?",
    answer:
      "You can create an account by downloading our app and following the registration prompts.",
    priority: "high",
  },
  {
    category: "getting started",
    question: "Is there a fee to sign up?",
    answer: "No, signing up for an account is free of charge.",
    priority: "high",
  },
  {
    category: "getting started",
    question: "How do I change my registered phone number?",
    answer:
      "You can reset your phone number in the app by logging in and changing the phone number. Instructions are in the app.",
    priority: "high",
  },
  {
    category: "getting started",
    question: "Do I need to verify my phone number?",
    answer:
      "Yes, for security purposes, we require users to verify their phone number during the registration process.",
    priority: "low",
  },
  {
    category: "getting started",
    question: "Can I use the app without creating an account?",
    answer:
      "No, you need to create an account to access our services and make bookings.",
    priority: "low",
  },
  {
    category: "getting started",
    question: "How can I download the app?",
    answer:
      "You can download our app for free from the App Store (iOS) or Google Play Store (Android).",
    priority: "low",
  },
  {
    category: "getting started",
    question: "Is there a tutorial available?",
    answer:
      "Yes, we provide a tutorial within the app to guide you through the booking process and app features.",
    priority: "low",
  },
  {
    category: "getting started",
    question: "Can I use multiple devices with my account?",
    answer: "Yes, you can log in to your account from multiple devices.",
    priority: "low",
  },
  {
    category: "getting started",
    question: "How do I update my account information?",
    answer:
      "You can update your account information such as name, email address, and payment methods through the 'Profile' or 'Account Settings' section in the app.",
    priority: "high",
  },
  {
    category: "getting started",
    question: "What languages are supported?",
    answer:
      "We currently support only English, however our customer support can answer questions in Luganda as well.",
    priority: "low",
  },
];

const Support = () => {
  const [searchInput, setSearchInput] = useState("");
  const [category, setCategory] = useState("all");
  const [selectedFaq, setSelectedFaq] = useState(null);

  const filterByCategory = (category) => {
    return faq.filter((faq) => faq.category === category);
  };

  function filterByText(searchText) {
    return faq.filter(
      (faq) =>
        faq.question.toLowerCase().includes(searchText.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  useEffect(() => {
    let filteredResults = [];

    switch (category) {
      case "all":
        filteredResults = faq;
        // code block
        break;
      case "services":
        filteredResults = filterByCategory("services");
        // code block
        break;
      case "drivers":
        filteredResults = filterByCategory("drivers");
        // code block
        break;
      case "passengers":
        filteredResults = filterByCategory("passengers");
        // code block
        break;
      case "partners":
        filteredResults = filterByCategory("partners");
        // code block
        break;
      case "bookings":
        filteredResults = filterByCategory("bookings");
        // code block
        break;
      case "getting started":
        filteredResults = filterByCategory("getting started");
        // code block
        break;
      default:
        // code block
        filteredResults = faq;
    }
    setSelectedFaq(filteredResults);
    setSearchInput("");
  }, [category]);

  useEffect(() => {
    let filteredResults = [];
    if (searchInput?.length > 3) {
      filteredResults = filterByText(searchInput);
      setSelectedFaq(filteredResults);
    }
  }, [searchInput]);
  return (
    <>
      <MetaHeaders
        title="Help & Support"
        url="https://cabbily.com/support"
        image="https://cabbily.com/images/support.svg"
        imageAlt="Car booking vector graphic."
        description="Hi, How can we help you? Search for the topic you need help with or browse the categories."
      />
      <div
        className="section-default"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Help & Support</h1>
              <h2 className="title-main-text">Hi, How can we help you?</h2>

              <p className="content-main-text fs-3">
                Search for the topic you need help with or browse the
                categories.
              </p>
              <Row>
                <Col md={4}>
                  <InputGroup size="md">
                    <FormControl
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={4}>
              <div className="d-flex flex-column">
                <Card
                  className={
                    category === "all"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("all")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <Box2
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={category === "all" ? "#6434d3" : "#334155"}
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">All FAQ</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      All the questions
                    </p>
                  </Card.Body>
                </Card>
                <Card
                  className={
                    category === "services"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("services")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <List
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={category === "services" ? "#6434d3" : "#334155"}
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">Services</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      Answers to queries about our products and services
                    </p>
                  </Card.Body>
                </Card>
                <Card
                  className={
                    category === "drivers"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("drivers")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <CarFront
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={category === "drivers" ? "#6434d3" : "#334155"}
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">Drivers</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      Responses to frequently asked questions by drivers
                    </p>
                  </Card.Body>
                </Card>
                <Card
                  className={
                    category === "passengers"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("passengers")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <People
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={
                          category === "passengers" ? "#6434d3" : "#334155"
                        }
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">Passengers</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      Commonly asked questions as a passenger
                    </p>
                  </Card.Body>
                </Card>
                <Card
                  className={
                    category === "partners"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("partners")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <PersonGear
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={category === "partners" ? "#6434d3" : "#334155"}
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">Partners</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      What our partners would like to know
                    </p>
                  </Card.Body>
                </Card>
                <Card
                  className={
                    category === "bookings"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("bookings")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <BookmarkCheck
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={category === "bookings" ? "#6434d3" : "#334155"}
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">Bookings</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      Answers to booking related questions
                    </p>
                  </Card.Body>
                </Card>
                <Card
                  className={
                    category === "getting started"
                      ? "bg-white flex-fill mt-2 border-primary"
                      : "bg-white flex-fill mt-2"
                  }
                  onClick={() => setCategory("getting started")}
                >
                  <Card.Body className="d-flex flex-column align-items-center">
                    <div>
                      <PersonPlus
                        size={40}
                        className="ms-2 mb-2 text-primary"
                        color={
                          category === "getting started" ? "#6434d3" : "#334155"
                        }
                      />
                    </div>

                    <p className="fs-3 fw-bold mb-0">Getting Started</p>
                    <p className="breadcrumb-text mb-0 text-center">
                      Responses to questions you might have before or when
                      starting out with our service.
                    </p>
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col>
              {searchInput?.length > 0 ? (
                <h3 className="fs-4 fw-bold text-primary">Search Results</h3>
              ) : (
                <h3 className="fs-4 fw-bold text-primary">
                  Solutions to Queries
                </h3>
              )}
              {selectedFaq &&
                selectedFaq?.map((item, index) => {
                  return (
                    <div key={`${index}`} className="mt-3">
                      <div className="d-flex flex-row">
                        {item?.priority === "high" ? (
                          <StarFill
                            size={18}
                            className="ms-2 text-primary"
                            color="#6434d3"
                          />
                        ) : (
                          <Dot
                            size={18}
                            className="ms-2 text-primary"
                            color="#6434d3"
                          />
                        )}
                        <div className="ms-3">
                          <h4 className="mb-0 fs-4">{item?.question}</h4>
                          <p className="mt-2 fs-5 fw-normal">{item?.answer}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Support;
