/* eslint-disable import/no-anonymous-default-export */
const handleError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.log(
    //   'A request was made and the server responded with a status code that falls out of the range of 2xx',
    //   error,
    // );
    return error.response.data.error;
  } else if (error.request) {
    // The request was made but no response was received
    // console.log('A request was made but no response was received');
    return "Request failed, check your network connection.";
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.log(
    //   'Something happened in setting up the request that triggered an Error',
    //   error,
    // );
    return "Service unavailable, please try again later.";
  }
};

export default {
  handleError,
};
