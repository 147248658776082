import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import MetaHeaders from "../../components/MetaHeaders";

const Terms = () => {
  return (
    <>
      <MetaHeaders
        title="Terms of Use"
        url="https://cabbily.com/terms"
        description="Apply to all services provided by Cabbily."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Terms of Use</h1>
              <p className="content-main-text fs-3">
                Apply to all services provided by Cabbily
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col>
              <p className="lead fw-bold">1.1 Scope and Definitions</p>
              <p>
                These General Conditions of Use apply to all services provided
                by Cabbily (defined herein below). Cabbily owns and operates the
                Site and App (defined herein below) in Uganda.
              </p>
              <p>Defined Terms</p>
              <p>
                “Cabbily” means Cabbily Technologies LTD, a company registered
                in Uganda, and hereafter referred to as Cabbily. The registered
                office of Cabbily is at Plot 8, Mutungo Zone 1, Kampala, Uganda.
              </p>
              <p>
                “Car Sharing” means the sharing of a Vehicle for a Trip by a Car
                Owner carrying a Co-Traveller for that Trip in exchange for a
                Cost Contribution;
              </p>
              <p>
                “Conditions” mean these General Conditions of Use, including the
                Good Conduct Charter and Privacy Policy of Cabbily as notified
                on the Site.
              </p>
              <p>
                “Cost Contribution” means the amount agreed between the Car
                Owner and the Co-Traveler in relation to the Trip which is
                payable by the Co-Traveler as their contribution towards the
                costs of the Trip.
              </p>
              <p>
                “Co-Traveller” or “Passenger” means a Member who has accepted an
                offer to be transported by a Car Owner and includes all other
                persons who accompany such Member in the Vehicle for the Trip.
              </p>
              <p>
                “Car Owner” or “Driver” means a Member who through the Site and
                App offers to share a car journey with a Co-Traveller in
                exchange for the Cost Contribution.
              </p>
              <p>“Member” refers to a registered user of the Site.</p>
              <p>
                “Service” refers to any service provided by Cabbily through the
                Site to any Member.
              </p>
              <p>
                “Site” means www.cabbily.com and any other website maintained or
                operated by Cabbily which offers similar services including any
                microsites, apps (Android or iOS) or sub-sites offered through
                any such website (e.g. “Maps” and “Help”).
              </p>
              <p>
                “Trip” means a given journey in relation to which a Car Owner
                and a Co-Traveler have agreed upon a transaction through the
                Site.
              </p>
              <p>
                “User Account” means an account with the Site opened by a Member
                and used in order to access the Service provided by Cabbily
                through the Site.
              </p>
              <p>
                “Vehicle” means the vehicle offered by a Car Owner for Car
                Sharing.
              </p>
              <p className="lead fw-bold">1.2 Acceptance of Conditions</p>
              <p>
                The Conditions apply to any and all use of the Site by a Member.
                By using the Site, the Members signify their acceptance to these
                Conditions in full and agree to be bound by them .
              </p>
              <p>
                No access to the Services will be permitted unless the
                Conditions are accepted in full. No Member is entitled to accept
                part only of the Conditions. If a Member does not agree to the
                Conditions, such Member may not use the Services.
              </p>
              <p>
                All Members agree to comply with the Conditions and accept that
                their personal data may be processed in accordance with the
                Privacy Policy.
              </p>
              <p>
                In the event that any Member fails to comply with any of the
                Conditions, Cabbily reserves the right, but not the obligation
                at its own discretion, to withdraw the User Account in question
                and suspend or withdraw all Services to that Member without
                notice.
              </p>
              <p>
                These Conditions are intended to create binding rights and
                obligations between Members and Cabbily in accordance with the
                Uganda Contract Act, 2010.
              </p>
              <p className="lead fw-bold">
                1.3 Variation of the Conditions, Site and Service
              </p>
              <p>
                Cabbily reserves the right to modify the Conditions at any time.
                In addition, Cabbily may vary or amend the Services provided
                through the Site, the Site functionality and/ or the “look and
                feel” of the Site at any time without notice and without
                liability to Members.
              </p>
              <p>
                Any modification to the Site, Services or Conditions will take
                effect as soon as such changes are published on the Site,
                subject to communication of any material change to the
                Conditions to the Members in an e-mail.
              </p>
              <p>
                Members will be deemed to have accepted any varied Conditions in
                the event that they use any Services offered through the Site
                following publication of the varied Conditions. Changes will not
                apply to any bookings which have been made prior to publication
                of the varied Conditions.
              </p>
              <p className="lead fw-bold">2. USE OF THE SERVICE</p>
              <p className="lead fw-bold">
                2.1 User Account and Accuracy of Information
              </p>
              <p>
                In order to use the Services each Member must create a User
                Account and agrees to provide any personal information requested
                by Cabbily. In particular, Members will be required to provide
                their first name, last name, age, title, valid telephone number
                and email address. Use of the Site is limited to those over the
                age of 18 years at the time of registration.
              </p>
              <p>
                Members agree and accept that all of the information they
                provide to Cabbily when setting up their User Account and at any
                other time shall be true, correct, complete and accurate in all
                respects. Members also agree that any information supplied to
                Cabbily or posted on the Site in connection with any Trip,
                Vehicle or Car Sharing will be true, accurate and complete.
              </p>
              <p>
                Members agree and understand that Cabbily does not undertake any
                verification to confirm the accuracy of any information provided
                by the Members on the Site or to a Car Owner or Co-Traveler, as
                the case maybe. Cabbily will not be liable to any Member in the
                event that any information provided by another Member is false,
                incomplete, inaccurate, misleading or fraudulent.
              </p>
              <p>
                Unless expressly agreed by Cabbily, Members are limited to one
                User Account per Member. No User Account may be created on
                behalf of or in order to impersonate another person.
              </p>
              <p className="lead fw-bold">
                2.2 No Commercial Activity and Status of Cabbily
              </p>
              <p>
                The Site and the Services are strictly limited to providing a
                Service for Car Owners and Co-Travelers to car share in a
                private capacity. The Services may not be used to offer or
                accept car sharing for hire or reward or for profit or in any
                commercial or professional context. The Services may be used
                only to offer or accept car sharing in exchange for sharing the
                cost of the Trip between the Car Owner and the Co-Traveler.
              </p>
              <p>
                Car Owners agree not to obtain any hire or reward or make profit
                in any form, from any Trip. The Service and the Cost
                Contribution may only be used to discharge the Car Owner’s costs
                and may not be used to generate any hiring charges or reward or
                profit in any form for the Car Owner. The Car Owner is not
                entitled to make profit by virtue of the amount of the Cost
                Contribution, the types of Trips offered by a Car Owner, the
                frequency of such Trips or the number of Co-Travelers
                transported. This applies to all activities, arrangements and
                Services booked using the Site and any additional services or
                activities which may be agreed between Car Owner and Co-Traveler
                through the Site.
              </p>
              <p>
                The Car Owner must not provide any additional services to the
                Co-Traveler in exchange for hiring charges or any reward or for
                profit or otherwise (and the Co-Traveler may not accept or ask
                for any such services) including (without limitation) package
                delivery, waiting time, additional drop offs and pick-ups and
                collecting additional passengers (other than the Co-Traveler).
              </p>
              <p>
                All Trips, collection points and destinations must be pre-agreed
                through the Site between the Car Owner and Co-Traveler. Car
                Owners may not collect any Co-Travelers from any location which
                has not been pre-agreed with the Co-Traveler through the Site.
              </p>
              <p>
                Members are reminded that using the Services and offering Trips
                for hire or reward or in a commercial or professional capacity
                may invalidate a Car Owner’s insurance and invite adverse legal
                actions by the road transport authorities. Cabbily shall not be
                in for any loss or damage incurred by a Member as a result of
                any or breach by a Member of these Conditions including where
                any Car Owner (in breach of these terms) offers Services through
                the Site in a professional or commercial capacity (thereby
                potentially invalidating their insurance) and breach of any
                agreement between the Car Owner and the Co-Traveler. Any
                offering of Trips in violation of the Conditions shall be at the
                sole risk such Member and Cabbily shall have no liability
                towards Members for such violations.
              </p>
              <p>Status of Cabbily</p>
              <p>
                Neither Cabbily nor the Site provides any transport services.
                The Site is a communications platform for Members to transact
                with one another. Cabbily does not interfere with Trips,
                destinations or timings. The agreement for car sharing is
                between the Car Owner and the Co-Traveler. Cabbily is not a
                party to any agreement or transaction between Members, nor is
                Cabbily liable in respect of any matter arising which relates to
                a booking between Members. Cabbily is not and will not act as an
                agent for any Member.
              </p>
              <p>
                Any breach of these Conditions will give rise to immediate
                suspension of such Member’s User Account and they may be
                restricted from accessing any further Services.
              </p>
              <p className="lead fw-bold">2.3 Types of Booking and Payment</p>
              <p>
                Cabbily offers to its Members an online booking service
                (hereinafter, the “Booking Service”) with a view to facilitate
                the booking of seats by Passengers.
              </p>
              <p>
                Cabbily reserves the right not to offer the Booking Service to a
                Trip o due to: (i) changes to applicable law, changes in the
                practice of regulatory authorities or changes in case law, (ii)
                changes to market practices or technology changes, (iii) changes
                of business considerations underlying the Booking Services, and
                (iv) other important and valid reasons
              </p>
              <p>
                Cabbily reserves the right to limit the number and/or class of
                Members eligible to the Booking Service for any Trip.
              </p>
              <p>
                2. How to book a Car-Sharing for a Trip: The Car Owner provides
                details of his or her Trip on the Site, specifying date and time
                for departure and destination points, the amount of the Cost
                Contribution per seat and all other relevant travel conditions.
              </p>
              <p>
                The Passenger books one or more seats in the car for that Trip
                from the Site exclusively by clicking on the button « Book »
                (hereinafter, the “Booking”).
              </p>
              <p>
                Cabbily will then send an email confirmation to each of the
                Driver and the Passenger confirming the Booking (hereinafter,
                the “Booking Confirmation”). Once a Booking Confirmation has
                been sent, the Booking is complete and a separate binding
                agreement for Car-Sharing relating to theTrip shall be formed
                between the Car-Owner and Passenger.
              </p>
              <p>
                Members accept that given the nature of the service Car Owners
                and Co-Travelers will have no recourse to Cabbily for any aspect
                of the transaction including in relation to cancellation, last
                minute changes, failure by the Car Owner or the Co-Traveler to
                turn up or non-payment of the Cost Contribution. In particular
                it is the Car Owner’s responsibility to collect payment from the
                Co-Traveler at the time of the Trip.
              </p>
              <p>
                Cabbily will not contact either party and will take no other
                steps whatsoever than those described above to manage the
                booking. The operation of the Trip is solely managed by the
                respective Car Owner and Co-Traveler(s).
              </p>
              <p>
                Please note that Cabbily reserves the right to change any aspect
                of the Site or the Service which may include adding new services
                (which may require payment) or withdrawing any existing
                Services. Cabbily does not guarantee that the Site will be
                functional at all times and Services may be suspended during
                such period when the Site is not in operation. Cabbily will not
                be liable to any of the Members in case where the Site is
                non-operational.
              </p>
              <p className="lead fw-bold">
                2.4 Car Owner and Co-Traveler Obligations
              </p>
              <p>Car Owner’s obligations</p>
              <p>The Car Owner agrees:</p>
              <p>
                That the Trip shall not be for any fraudulent, unlawful or
                criminal activity.
              </p>
              <p>
                That they will procure for the Vehicle, a comprehensive
                insurance policy, which provides insurance cover to the
                occupants in the Vehicle and covers third party liability.
              </p>
              <p>
                That they will present themselves on time and at the place
                agreed with the specified Vehicle;
              </p>
              <p>
                That they will immediately inform all Co-Travelers of any change
                whatsoever to the Trip. If one or more Co-Travelers have made a
                booking and the Car Owner decides to change any aspect of the
                Trip, the Car Owner undertakes to contact all Co-Travelers who
                have made a booking in relation to that Trip and to obtain the
                agreement of all Co-Travelers to the change. If a Co-Traveler
                refuses the change, they are entitled to a full refund and
                without any compensation being paid to the Car Owner.
              </p>
              <p>
                The Car Owner must comply with the Good Conduct Charter at all
                times.
              </p>
              <p>
                The Car Owner must wait for the Co-Traveler at the pickup point
                for at least 30 minutes after the agreed time (however, the
                Co-Traveler is expected to be punctual).
              </p>
              <p>Co-Traveler obligations</p>
              <p>The Co-Traveler agrees:</p>
              <p>
                That the Trip shall not be for any fraudulent, unlawful or
                criminal activity.
              </p>
              <p>
                That they will present themselves on time and at the place
                agreed with the Car Owner;
              </p>
              <p>
                That they will immediately inform the Car Owner or Cabbily if
                they are required to cancel a Trip.
              </p>
              <p>
                That they will comply with the Good Conduct Charter at all
                times.
              </p>
              <p>
                The Co-Traveler agrees to wait at the pickup point for at least
                30 minutes after the agreed time for the Car Owner to arrive.
              </p>
              <p>That they will pay the Cost Contribution to the Car Owner.</p>
              <p>
                If the Co-Traveler or Car Owner fail to comply with any of these
                terms or any other Conditions Cabbily reserves the right to keep
                information relating to the breach, to publish or disclose this
                information on the Member’s online profile and to suspend or
                withdraw the Member’s access to the Site.
              </p>
              <p>
                That they shall ensure that all other persons who accompany the
                Co-Traveller in the Trip comply with these Conditions as
                applicable to a Co-Traveller.
              </p>
              <p className="lead fw-bold">2.5 Insurance</p>
              <p>
                The Car Owner agrees and undertakes to take out and maintain a
                comprehensive insurance to cover third party liability, the
                occupants of the Vehicle and the Trip offered or booked through
                the Site. The Car Owner agrees that they will, on request,
                provide the Co-Traveler with evidence, in advance of the Trip,
                of the complete validity of its insurance policy. The Car Owner
                also undertakes to hold a valid driving licence and that the Car
                Owner will own or will be entitled to use the Vehicle and that
                the Vehicle will have a valid PUC (Pollution Under Control)
                certificate and the Co-Traveler is entitled to request evidence
                of the Car Owner’s insurance, registration certificate (‘log
                book’), driving licence and PUC certificate at any time up to
                completion of the Trip.
              </p>
              <p>
                It is Cabbily’s understanding that governmental authorities take
                the view that a Co-Traveler who contributes only towards travel
                expenses is treated as travelling without hire or reward to the
                driver, and is therefore a third party passenger who is covered
                by comprehensive third party insurance policy in Uganda. However
                Cabbily gives no warranty or assurance in this regard and it is
                the Car Owner’s responsibility to verify that their insurance
                provides adequate cover.
              </p>
              <p>
                It is up to each Car Owner and Co-Traveler to confirm with each
                other that the Car Owner is covered by valid insurance. The Car
                Owner must confirm that their insurance policy allows them to
                carry Co-Travelers and that their insurance policy covers all
                Co-Travelers and any accident or incident which may occur during
                a Trip.
              </p>
              <p>
                The Car Owner and the Co-Traveler are aware that standard
                non-commercial insurance policies may refuse to cover loss or
                damage arising in the event that the Car Owner had made or was
                seeking to make a profit.
              </p>
              <p>
                The Car Owner may collect no payment from the Co-Traveler other
                than the Cost Contribution and the Car Owner must not in any
                event provide Vehicle on hire or for reward in any form.
              </p>
              <p>
                The Car Owner therefore undertakes to calculate their expenses
                (fuel, toll, maintenance, repairs, depreciation and insurance of
                their vehicle) and guarantees that the total Cost Contributions
                requested from their Co-Travelers does not result in the Vehicle
                running for hire or for reward.
              </p>
              <p>
                If the Car Owner does receive any hiring charges or reward, or
                if the insurers repudiate or refuse to accept any claim arising
                during a Trip for any other reason, the Car Owner will be
                responsible for the financial consequences, losses and damages
                arising and Cabbily will not be liable under any circumstances
                to the Car Owner or the Co-Traveler.
              </p>
              <p>
                Cabbily reserves the right, but not the obligation at its own
                discretion, to suspend immediately the account of a user
                including the money displayed and to make aware to competent
                authorities any professional activity.
              </p>
              <p className="lead fw-bold">
                2.6 Management of Disputes Between Members
              </p>
              <p>
                Cabbily may at its sole discretion provide its Members with an
                online service for resolving disputes. This service is
                non-binding. Cabbily is under no obligation to seek to resolve
                disputes and this service is offered at Cabbily’s sole
                discretion and may be withdrawn at any time.
              </p>
              <p className="lead fw-bold">
                2.7 Verification of IDs and Phone number
              </p>
              <p>
                By accepting the terms and conditions contained herein, every
                Member or any person who wishes to register as a Member hereby
                agrees and consents to the fact that Cabbily may collect IDs /
                documents belonging to them including but not limited to
                passport, National ID card and Driver Licence for the purpose of
                verification of the information contained in such IDs /
                documents by third party service providers.
              </p>
              <p>
                In order to increase trustworthiness, prevent typos and wrong
                numbers, Member may verify their mobile number. The Member may
                do this by providing Cabbily with their mobile phone number,
                after which the Member will receive a SMS with a 5-digit code
                which can be validated on the Site. This service is free of
                charge, except for the possible cost levied by a Member’s mobile
                phone operator for receiving the SMS.
              </p>
              <p className="lead fw-bold">
                2.8 International Trips and International Bookings
              </p>
              <p>
                Bookings may be made through the Site for international Trips.
                An International Trip means any Trip which includes any travel
                outside of Uganda. If a booking is made for an International
                Trip Car Owners must ensure that their insurance covers travel
                outside of Uganda. The Car Owner must also ensure that their
                vehicle is compliant with all relevant rules and restrictions
                applicable in any overseas country.
              </p>
              <p className="lead fw-bold">2.9 Messages between Members</p>
              <p>
                Cabbily may review, scan, and moderate the messages the Members
                exchange with each others through the Site in particular for
                fraud prevention, customer support purposes, enforcement of the
                contracts entered into with Cabbily’s members (such as the
                Conditions) and ensure compliance with applicable law. For
                example, in order to prevent the circumventing of its Booking
                Service, Cabbily may scan and analyse messages sent through the
                platform to check that they do not include any contact details
                or references to other website.
              </p>
              <p>
                By using the Site and accepting the T&amp;C’s, the Member agrees
                that Cabbily, in its sole discretion, may review, analyse and
                moderate the messages exchanged through the Site.
              </p>
              <p>
                By using the messaging feature of the Site, the Member
                undertakes not to write and/or send any message prohibited by
                applicable law. In particular the Member undertakes to refrain
                from writting/sending any message which content:
              </p>
              <ul>
                <li>
                  belongs to another person and to which the Member does not
                  have any right to;
                </li>
                <li>
                  is grossly harmful, harassing, blasphemous defamatory,
                  obscene, pornographic ;
                </li>
                <li>harms minors in any way;</li>
                <li>
                  infringes any patent, trademark, copyright or other
                  proprietary rights;
                </li>
                <li>violates any law for the time being in force;</li>
                <li>
                  deceives or misleads the addressee about the origin of such
                  messages or communicates any information which is grossly
                  offensive or menacing in nature;
                </li>
                <li>impersonates another person;</li>
                <li>
                  contains software viruses that limit the functionality of any
                  computer resource; and
                </li>
                <li>
                  threatens the unity, integrity, defense, security or
                  sovereignty of Uganda, friendly relations with foreign states,
                  or public order or causes incitement to the commission of any
                  cognizable offence or prevents investigation of any offence or
                  is insulting any other nation.
                </li>
              </ul>
              <p>
                In addition, the Member undertakes to send messages only with
                respect to the booking of Car-sharing and in line with the
                purposes of this Site. The Member undertakes to refrain from
                using the messages for private or confidential communications.
              </p>
              <p>
                Cabbily reserves the right to filter or delete the messages and
                suspend or terminate the User Account of the Member and the
                access of the Member to the Site if it appears during the
                moderation of the messages sent by the Member that s/he does not
                comply with the Conditions and/or applicable law.
              </p>
              <p className="lead fw-bold">2.10 Contacting Members</p>
              <p>
                By accepting the terms and conditions contained herein, every
                Member hereby agrees and gives consent to Cabbily to communicate
                via phone calls, sms, email and such other means as Cabbily may
                deem fit. Such communications to Members may be recorded through
                technical support provided by third parties for the purpose of
                training, quality and for regularly updating the Members about
                the services of Cabbily.
              </p>

              <div>
                <p className="lead fw-bold">3. DISCLAIMER OF LIABILITY</p>
              </div>

              <p>
                3.1 Members may access the Services on the Site at their own
                risk and using their best and prudent judgment before entering
                into any arrangements with other Members through the Site.
                Cabbily will neither be liable nor responsible for any actions
                or inactions of Members nor any breach of conditions,
                representations or warranties by the Members. Cabbily hereby
                expressly disclaims and any and all responsibility and liability
                in arising out of the use of the Site.
              </p>

              <div>
                <p>
                  3.2 Cabbily expressly disclaims any warranties or
                  representations (express or implied) in respect of Trips,
                  accuracy, reliability and completeness of information provided
                  by Members, or the content (including details of the Trip and
                  Cost Contribution) on the Site. While Cabbily will take
                  precautions to avoid inaccuracies in content of the Site, all
                  content and information, are provided on an as is where is
                  basis, without warranty of any kind. Cabbily does not
                  implicitly or explicitly support or endorse any of the Members
                  availing Services from the Site.
                </p>
                <p>
                  3.3 Cabbily is not a party to any agreement between a Car
                  Owner and Co-Traveler and will not be liable to either the Car
                  Owner or the Co-Traveler unless the loss or damage incurred
                  arises due to Cabbily’s negligence.
                </p>
                <p>
                  3.4 Cabbily shall not be liable for any loss or damage arising
                  as a result of:
                </p>
                <p>
                  A false, misleading, inaccurate or incomplete information
                  being provided by a Member;
                </p>
                <p>The cancellation of a Trip by a Car Owner or Co-Traveler;</p>
                <p>
                  Any failure to make payment of a Cost Contribution (for the
                  free service without booking);
                </p>
                <p>
                  Any fraud, fraudulent misrepresentation or breach of duty or
                  breach of any of these Conditions by a Car Owner or
                  Co-Traveler before, during or after a Trip.
                </p>
                <p>
                  Cabbily will not be liable to any Member for any business,
                  financial or economic loss or for any consequential or
                  indirect loss such as lost reputation, lost bargain, lost
                  profit, lost of anticipated savings or lost opportunity
                  arising as a result of the services provided by Cabbily
                  (whether suffered or incurred as a result of the Cabbily’s
                  negligence or otherwise) except in the case of fraud, wilful
                  concealment or theft.
                </p>
                <p>
                  Cabbily’s liability to any Member for all losses in respect of
                  any Trip is capped at the sum of UGX 100,000 /- (Uganda
                  shillings one hundred thousand only).
                </p>
                <p>
                  Cabbily will not be liable to any Member in relation to any
                  Trip unless Cabbily is notified of a claim relating to that
                  Trip within 3 months of completion of the Trip
                </p>
                <p>
                  Given that Car Owners are required to hold valid insurance to
                  cover a Trip and given that Cabbily’s service is limited to
                  putting Car Owners and Co-Travelers in touch with each other
                  and cannot oversee any Trip, Members accept that the
                  limitations on the Cabbily’s liability set out above are
                  reasonable.
                </p>
              </div>
              <div></div>
              <div>
                <p className="lead fw-bold">4. INDEMNITY AND RELEASE</p>
              </div>
              <div></div>
              <div>
                <p>
                  4.1 Members will indemnify and hold harmless Cabbily, its
                  subsidiaries, affiliates and their respective officers,
                  directors, agents and employees, from any claim or demand, or
                  actions including reasonable attorney’s fees, made by any
                  third party or penalty imposed due to or arising out of your
                  breach of these Conditions or any document incorporated by
                  reference, or your violation of any law, rules, regulations or
                  the rights of a third party.
                </p>
                <p>
                  4.2 Members release Cabbily and/or its affiliates and/or any
                  of its officers and representatives from any cost, damage,
                  liability or other consequence of any of the actions/inactions
                  of the Members and specifically waiver any claims or demands
                  that they may have in this behalf under any statute, contract
                  or otherwise.
                </p>
              </div>
              <div></div>
              <div>
                <p className="lead fw-bold">5. GENERAL TERMS</p>
              </div>
              <div></div>
              <div>
                <p className="lead fw-bold">5.1 Relationship</p>
              </div>
              <p>
                No arrangement between the Members and Cabbily shall constitute
                or be deemed to constitute an agency, partnership, joint venture
                or the like between the Members and Cabbily.
              </p>
              <div></div>
              <div>
                <p className="lead fw-bold">
                  5.2 Suspension or Withdrawal of Site Access
                </p>
              </div>
              <p>
                In the event of non-compliance on your part with all or some of
                the Conditions, you acknowledge and accept that Cabbily can at
                any time, without prior notification, interrupt or suspend,
                temporarily or permanently, all or part of the service or your
                access to the Site (including in particular your User Account).
              </p>
              <div></div>
              <div>
                <p className="lead fw-bold">5.3 Intellectual Property</p>
              </div>
              <div>
                <p>
                  The format and content included on the Site, such as text,
                  graphics, logos, button icons, images, audio clips, digital
                  downloads, data compilations, and software, is the property of
                  Cabbily, its affiliates or its content suppliers and is
                  protected by Uganda and international copyright, authors’
                  rights and database right laws.
                </p>
                <p>
                  All rights are reserved in relation to any registered and
                  unregistered trademarks (whether owned or licensed to Cabbily)
                  which appear on the Site.
                </p>
                <p>
                  The Site or any portion of the Site may not be reproduced,
                  duplicated, copied, sold, resold, visited, or otherwise
                  exploited for any commercial purpose without the express
                  written consent of Cabbily. No person is entitled to
                  systematically extract and/or re-utilise parts of the contents
                  of the Site without the express written consent of Cabbily. In
                  particular, the use of data mining, robots, or similar data
                  gathering and extraction tools to extract (whether once or
                  many times) for re-utilisation of any substantial parts of
                  this Site is strictly prohibited.
                </p>
              </div>
              <div></div>
              <div>
                <p className="lead fw-bold">
                  5.4 Content of the Site Provided by the Members
                </p>
              </div>
              <div>
                <p>
                  By displaying content on this Site, Members expressly grant a
                  license to Cabbily to display the content and to use it for
                  any of our other business purposes.
                </p>
                <p>
                  Members of this Site are expressly asked not to publish any
                  defamatory, misleading or offensive content or any content
                  which infringes any other persons intellectual property rights
                  (e.g. copyright). Any such content which is contrary to
                  Cabbily’s policy and Cabbily does not accept liability in
                  respect of such content, and the Member responsible will be
                  personally liable for any damages or other liability arising
                  and agrees to indemnify Cabbily in relation to any liability
                  it may suffer as a result of any such content. However as soon
                  as Cabbily becomes aware of infringing content, Cabbily shall
                  do everything it can to remove such content from the Site as
                  soon as possible.
                </p>
              </div>
              <div></div>
              <div>
                <p className="lead fw-bold">5.5 Partner Sites</p>
              </div>
              <div>
                <p>
                  Cabbily reserves the right to reproduce any information that
                  appears on the Site or on the partner sites.
                </p>
                <p>
                  In particular, ads published on one of the sites maintained or
                  co-maintained by Cabbily may be reproduced on other sites
                  maintained or co-maintained by Cabbily or third parties.
                </p>
              </div>
              <div></div>
              <div>
                <p className="lead fw-bold">6. LAW AND JURISDICTION</p>
              </div>
              <p>
                These terms shall be governed by the law of Uganda and any
                disputes arising in relation to these terms shall be subject to
                the jurisdiction of the Courts of Uganda.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Terms;
