import React from "react";
import MetaHeaders from "../components/MetaHeaders";
import { Row, Col, Card, Container, Image, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const ErrorPage = () => {
  return (
    <>
      <MetaHeaders
        title="Error 404"
        url="https://cabbily.com/404"
        image="https://cabbily.com/images/error.svg"
        imageAlt="Error 404 Image"
        description="Error 404 occured loading page."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col md={6}>
              <Image
                src="/images/error.svg"
                rounded
                fluid
                alt="Cabbily App showcase"
              />
            </Col>
            <Col md={6}>
              <h1 className="fs-4 fw-bold text-primary">Error 404</h1>
              <h2 className="title-main-text">Page does not exist</h2>

              <p className="content-main-text fs-3">
                The page you are accessing is not available.
              </p>
              <LinkContainer to="/">
                <Button variant="primary" type="submit" className="mt-3">
                  Return to Home
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ErrorPage;
