import React from "react";
import { Row, Col, Button, Container, Image } from "react-bootstrap";
import {
  Icon1Circle,
  Icon2Circle,
  Icon3Circle,
  ArrowRight,
} from "react-bootstrap-icons";
import { LinkContainer } from "react-router-bootstrap";
import MetaHeaders from "../../components/MetaHeaders";

const CarpoolService = () => {
  return (
    <>
      <MetaHeaders
        title="Carpool Services"
        url="https://cabbily.com/carpool"
        image="https://cabbily.com/images/carpool.svg"
        imageAlt="Car booking vector graphic."
        description="Share a ride with people going the same way. Our platform enables you to share a ride with people going the same way allowing you to share travel costs, travel in comfort and make new friends along the way. We only provide intercity trips e.g. Kampala to Gulu, Kampala to Masaka."
      />
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundSize: "auto",
          backgroundPosition: "left",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1 className="fs-4 fw-bold text-primary">Carpool Services</h1>
              <h2 className="title-main-text">
                Share a ride with people going the same way
              </h2>

              <p className="content-main-text fs-3">
                Our platform enables you to share a ride with people going the
                same way allowing you to share travel costs, travel in comfort
                and make new friends along the way. We only provide intercity
                trips e.g. Kampala to Gulu, Kampala to Masaka.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-white"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            className="align-items-center"
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={8}>
              <h2 className="fs-6 mb-0 text-primary fw-normal">DRIVERS</h2>
              <p className="fs-3 fw-bold">Share your ride</p>
              <Row xs={1} md={3} className="g-4">
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon1Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Post a trip</p>
                    </div>
                    <p className="content-main-text">
                      Add a ride setting a specific origin, destination and a
                      time of departure. You can add a trip 1 month in advance.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon2Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Get bookings</p>
                    </div>
                    <p className="content-main-text">
                      Users view the trip and book if they are going to the same
                      place. You get to approve or reject booking requests as
                      well as chat with potential passengers in the app.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon3Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Pickup passenger</p>
                    </div>
                    <p className="content-main-text">
                      Pickup the passenger at an agreed meeting point and move
                      together. Passengers pay using mobile money (in app) or
                      cash.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={4}>
              <Image src="/images/carpool.svg" fluid />
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="section-gray"
        style={{
          backgroundImage: 'url("/images/bg-dot-web.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto",
          backgroundPosition: "right",
        }}
      >
        <Container>
          <Row
            className="align-items-center"
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={4}>
              <Image src="/images/passenger.svg" fluid />
            </Col>
            <Col md={8}>
              <h2 className="fs-6 mb-0 text-primary fw-normal">PASSENGERS</h2>
              <p className="fs-3 fw-bold">Get a ride</p>
              <Row xs={1} md={3} className="g-4">
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon1Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Book a trip</p>
                    </div>
                    <p className="content-main-text">
                      Select a trip, choose the number of seats you want and
                      book from the available carpool rides in the app.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon2Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Driver Confirmation</p>
                    </div>
                    <p className="content-main-text">
                      Carpool driver confirms they would like to travel with
                      you. You get to chat with the driver and confirm if they
                      are a good fit. Cancel anytime if not sure.
                    </p>
                  </div>
                </Col>
                <Col className="d-flex">
                  <div className="flex-fill border-start ps-md-2">
                    <div className="d-flex flex-row align-items-center">
                      <Icon3Circle
                        size={45}
                        className="me-2 mb-2 text-primary"
                        color="#6434d3"
                      />
                      <p className="fw-bold mb-0">Go to pickup</p>
                    </div>
                    <p className="content-main-text">
                      Meetup with the driver at an agreed meeting point and move
                      together. Pay using mobile money (in app) or cash during
                      the trip or when you get to your destination.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section-default">
        <Container>
          <Row
            className="align-items-center"
            style={{
              backgroundImage: 'url("/images/bg-box-web.png")',
              backgroundSize: "auto",
              backgroundPosition: "left",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Col md={6}>
              <h2 className="fs-6 mb-0 text-primary fw-normal">WHY CARPOOL</h2>
              <p className="fs-3 fw-bold mb-0"> It's a win for everyone</p>
              <p className="content-main-text">
                For drivers, you get to save on fuel costs, with up to 80%
                saving if you get fully booked. For passengers, you get to
                travel in comfort and save on the travel costs. For both, you
                get to have company on the journey, make new friends and reduce
                carbon emissions.
              </p>
              <LinkContainer to={`/download-app`} className="animate">
                <Button variant="outline-primary" size="lg">
                  Book a Carpool Today{" "}
                  <ArrowRight size={22} className="me-2 ms-2" />
                </Button>
              </LinkContainer>
            </Col>
            <Col md={6}>
              <Image src="/images/celebration.svg" fluid />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CarpoolService;
